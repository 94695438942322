
import Splide from '@splidejs/splide';

let titleSlides = document.getElementsByClassName('title-slider');

    document.addEventListener('DOMContentLoaded', function () {
        for (let i = 0; i < titleSlides.length; i++) {

            let splide = new Splide(titleSlides[i], {
                type: 'slide',
                perPage: 1,
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: false,
                width: '200vw',
                autoplay: 'play',
                direction: 'rtl'
            });

            //Set overflow when slider is active
            splide.on('mounted', function () {
                let track = splide.root.querySelector('.splide__track');
                track.classList.add('hasOverflow');
            });

            splide.mount();
        }
    });
